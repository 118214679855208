import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

function GoogleAuthButton() {
  const handleSuccess = async (response) => {
    const { credential } = response;

    try {
      // Send the credential token to the backend for verification
      const result = await axios.post('http://localhost:8000/api/auth/google/token', {
        token: credential,
      });

      console.log('Backend Response:', result.data);
    } catch (error) {
      console.error('Error authenticating with backend:', error.response?.data || error.message);
    }
  };

  const handleFailure = (error) => {
    console.error('Google Login Failed:', error);
  };

  return (
    <GoogleLogin
      onSuccess={handleSuccess}
      onError={handleFailure}
    />
  );
}

export default GoogleAuthButton;
