// import { useEffect, useState, useRef } from "react";
// import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// import Helpers from "../../Config/Helpers";
// // import './Screens/Layout.css'

// const AdminLayout = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [showMobileNav, setShowMobileNav] = useState(false);
//     const [isCompact, setIsCompact] = useState(false);
//     const sidebarContentRef = useRef(null);

//     const logout = (e) => {
//         e.preventDefault();
//         Helpers.toast("success", "Logged out successfully");
//         localStorage.clear();
//         navigate('/');
//     }

//     const toggleSidebar = () => {
//         setIsCompact(!isCompact);
//         if (sidebarContentRef.current) {
//           sidebarContentRef.current.scrollLeft = isCompact ? 0 : sidebarContentRef.current.scrollWidth;
//         }
//       };

//     useEffect(() => {
//         Helpers.toggleCSS();
//     }, [location.pathname]);


//     return (
//         <div class="nk-app-root" data-sidebar-collapse="lg">
//             <div class="nk-main">
//                 {showMobileNav && (<div onClick={() => setShowMobileNav(false)} className="sidebar-overlay"></div>)}
//                 <div class={`nk-sidebar nk-sidebar-fixed ${isCompact && "is-compact"} ${showMobileNav && "sidebar-active"}`} id="sidebar">
//                 <div class="nk-compact-toggle">
//                     <button onClick={toggleSidebar} class="btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3"><em class={`icon off ni ${isCompact ? "ni-chevron-right" : "ni-chevron-left"}`}></em></button>
//                 </div>
//                     <div class="nk-sidebar-element nk-sidebar-head">
//                         <div class="nk-sidebar-brand">
//                             <a href="/" class="logo-link">
//                                 <div class="logo-wrap">
//                                     <img class="logo-img logo-light" src="/logo-white.png" alt="" />
//                                     <img class="logo-img logo-dark" src="/logo-white.png" alt="" />
//                                     <img class="logo-img logo-icon" src="/logo-white.png" alt="" />
//                                 </div>
//                             </a>
//                         </div>
//                     </div>
//                     <div class="nk-sidebar-element nk-sidebar-body">
//                         <div class="nk-sidebar-content h-100" data-simplebar>
//                             <div class="nk-sidebar-menu" style={{ overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none', scrollBehavior: 'smooth' }}>
//                                 <ul class="nk-menu">
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/dashboard'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span><span class="nk-menu-text">Dashboard</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/users'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-users"></em></span><span class="nk-menu-text">Users</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/chat-history'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-clock"></em></span><span class="nk-menu-text">Chat History</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/categories'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-tag"></em></span><span class="nk-menu-text">Categories</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/prompts'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-file"></em></span><span class="nk-menu-text">Prompts</span>
//                                         </Link>
//                                     </li>
//                                     {/* <li class="nk-menu-item">
//                                         <Link to={'/admin/instructions'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-info"></em></span><span class="nk-menu-text">Instructions</span>
//                                         </Link>
//                                     </li> */}
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/buttons'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-view-grid"></em></span><span class="nk-menu-text">Automation Buttons</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/instructions'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-info"></em></span><span class="nk-menu-text">Instructions</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/profile'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span><span class="nk-menu-text"> Profile</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/pricing-plans'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-sign-usdc"></em></span><span class="nk-menu-text">Adm Pricing Plans</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <Link to={'/admin/Settings'} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-tag"></em></span><span class="nk-menu-text"> Settings</span>
//                                         </Link>
//                                     </li>
//                                     <li class="nk-menu-item">
//                                         <a href="#!" onClick={logout} class="nk-menu-link">
//                                             <span class="nk-menu-icon"><em class="icon ni ni-signout"></em></span><span class="nk-menu-text">Sign Out</span>
//                                         </a>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="nk-sidebar-element nk-sidebar-footer">
//                         <div class="nk-sidebar-footer-extended pt-3">
//                             <div class="border border-light rounded-3">
//                                 <a class="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3" href="#!">
//                                     <div class="media-group">
//                                         <div class="media media-sm media-middle media-circle text-bg-primary"><img src={Helpers.serverImage(Helpers.authUser.profile_pic)} alt="" /></div>
//                                         <div class="media-text">
//                                             <h6 class="fs-6 mb-0 color-white">{Helpers.authUser.name}</h6>
//                                         </div>
//                                         <em class="icon ni ni-chevron-right ms-auto ps-1 color-white"></em>
//                                     </div>
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="nk-wrap">
//                     <div class="nk-header nk-header-fixed">
//                         <div class="container-fluid">
//                             <div class="nk-header-wrap">
//                                 <div class="nk-header-logo ms-n1">
//                                     <div class="nk-sidebar-toggle me-1">
//                                         <button
//                                             onClick={() => setShowMobileNav(true)}
//                                             class="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none"
//                                             >
//                                             <em class="icon ni ni-menu"> </em>
//                                         </button>
//                                         <button
//                                             onClick={() => setShowMobileNav(true)}
//                                             class="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"
//                                             >
//                                             <em class="icon ni ni-menu"> </em>
//                                         </button>
//                                         </div>
//                                     <a href="#!" class="logo-link">
//                                         <div class="logo-wrap">
//                                             <img class="logo-img logo-light" src="images/logo.png" srcset="https://copygen.themenio.com/dashboard/images/logo2x.png 2x" alt="" />
//                                             <img class="logo-img logo-dark" src="images/logo-dark.png" srcset="https://copygen.themenio.com/dashboard/images/logo-dark2x.png 2x" alt="" />
//                                             <img class="logo-img logo-icon" src="images/logo-icon.png" srcset="https://copygen.themenio.com/dashboard/images/logo-icon2x.png 2x" alt="" />
//                                         </div>
//                                     </a>
//                                 </div>
//                                 <div class="nk-header-tools">
//                                     <ul class="nk-quick-nav ms-2">
//                                         <li class="dropdown d-inline-flex">
//                                             <a data-bs-toggle="dropdown" class="d-inline-flex" href="#">
//                                                 <div class="media media-md media-circle media-middle text-bg-primary"><img src="images/avatar/a.png" /></div>
//                                             </a>
//                                             <div class="dropdown-menu dropdown-menu-md rounded-3">
//                                                 <div class="dropdown-content py-3">
//                                                     <div class="border border-light rounded-3">
//                                                         <div class="px-3 py-2 bg-white border-bottom border-light rounded-top-3">
//                                                             <div class="d-flex flex-wrap align-items-center justify-content-between">
//                                                                 <h6 class="lead-text">Free Plan</h6>
//                                                                 <a class="link link-primary" href="#"><em class="ni ni-spark-fill icon text-warning"></em><span>Upgrade</span></a>
//                                                             </div>
//                                                             <div class="progress progress-md"><div class="progress-bar" data-progress="25%"></div></div>
//                                                             <h6 class="lead-text mt-2">1,360 <span class="text-light">words left</span></h6>
//                                                         </div>
//                                                         <a class="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3" href="#!">
//                                                             <div class="media-group">
//                                                                 <div class="media media-sm media-middle media-circle text-bg-primary"><img src="images/avatar/a.png" /></div>
//                                                                 <div class="media-text">
//                                                                     <h6 class="fs-6 mb-0 color-white">Shawn Mahbub</h6>
//                                                                     <span class="text-light fs-7">shawn@websbd.com</span>
//                                                                 </div>
//                                                                 <em class="icon ni ni-chevron-right ms-auto ps-1"></em>
//                                                             </div>
//                                                         </a>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <Outlet />
//                     <div class="nk-footer">
//                         <div class="container-xl">
//                             <div class="d-flex align-items-center flex-wrap justify-content-between mx-n3">
//                                 <div class="nk-footer-links px-3">
//                                     <ul class="nav nav-sm">

//                                     </ul>
//                                 </div>
//                                 <div class="nk-footer-copyright fs-6 px-3">&copy; 2023 All Rights Reserved to <a href="#!">HumGPT</a>.</div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default AdminLayout;

import { useEffect, useState, useRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
// import './Screens/Layout.css'
import logo from './../../../humgpt-white-logo.png';

const AdminLayout = () => {

    const currentYear = new Date().getFullYear();

    const location = useLocation();
    const navigate = useNavigate();
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [isCompact, setIsCompact] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const sidebarContentRef = useRef(null);

    const logout = (e) => {
        e.preventDefault();
        Helpers.toast("success", "Logged out successfully");
        localStorage.clear();
        navigate('/');
    }

    const toggleSidebar = () => {
        setIsCompact(!isCompact);
        if (sidebarContentRef.current) {
            sidebarContentRef.current.scrollLeft = isCompact ? 0 : sidebarContentRef.current.scrollWidth;
        }
    };

    useEffect(() => {
        Helpers.toggleCSS();
    }, [location.pathname]);

    const handleMouseEnter = () => {
        if (isCompact) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        if (isCompact) {
            setIsHovered(false);
        }
    };

    return (
        <div className="nk-app-root" data-sidebar-collapse="lg">
            <div className="nk-main">
                {showMobileNav && (<div onClick={() => setShowMobileNav(false)} className="sidebar-overlay"></div>)}
                <div
                    className={`nk-sidebar nk-sidebar-fixed ${isCompact ? "is-compact" : ""} ${showMobileNav ? "sidebar-active" : ""}`}
                    id="sidebar"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="nk-compact-toggle">
                        <button onClick={toggleSidebar} className="btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3">
                            <em className={`icon off ni ${isCompact ? "ni-chevron-right" : "ni-chevron-left"}`}></em>
                        </button>
                    </div>
                    <div className="nk-sidebar-element nk-sidebar-head">
                        <div className="nk-sidebar-brand">
                            {(!isCompact || isHovered) && (
                                <a href="/" className="logo-link">
                                    {/* <div className="logo-wrap">
                                        <img className="logo-img logo-light" src="/logo-white.png" alt="" />
                                        <img className="logo-img logo-dark" src="/logo-white.png" alt="" />
                                        <img className="logo-img logo-icon" src="/logo-white.png" alt="" />
                                    </div> */}
                                    <div class="logo-wrap">
                                        <img
                                            class="logo-img logo-light"
                                            src={logo}
                                            alt=""
                                            style={{ width: '120px', height: '120px', marginTop: '-40px', marginLeft: '-10px' }}
                                        />
                                        <img
                                            class="logo-img logo-dark"
                                            src={logo}
                                            alt=""
                                            style={{ width: '120px', height: '120px', marginTop: '-40px', marginLeft: '-10px' }}
                                        />
                                        <img
                                            class="logo-img logo-icon compact-logo"
                                            src={logo}
                                            alt=""
                                            style={{ width: '120px', height: '120px', marginTop: '-40px', marginLeft: '-10px' }}
                                        />
                                    </div>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="nk-sidebar-element nk-sidebar-body">
                        <div className="nk-sidebar-content h-100" data-simplebar>
                            <div className="nk-sidebar-menu" style={{ overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none', scrollBehavior: 'smooth' }}>
                                <ul className="nk-menu">
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/dashboard'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span><span className="nk-menu-text">Dashboard</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/users'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-users"></em></span><span className="nk-menu-text">Users</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/chat-history'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-clock"></em></span><span className="nk-menu-text">Chat History</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/categories'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-tag"></em></span><span className="nk-menu-text">Categories</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/prompts'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-file"></em></span><span className="nk-menu-text">Prompts</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/buttons'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-view-grid"></em></span><span className="nk-menu-text">Automation Buttons</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/instructions'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-info"></em></span><span className="nk-menu-text">Instructions</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/profile'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span><span className="nk-menu-text"> Profile</span>
                                        </Link>
                                    </li>
                                    {/* <li className="nk-menu-item">
                                        <Link to={'/admin/packages'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-money"></em></span><span className="nk-menu-text">Admin Packages</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/transactions'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-money"></em></span><span className="nk-menu-text">Admin Transactions</span>
                                        </Link>
                                    </li> */}
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/pricing-plans'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-sign-usdc"></em></span><span class="nk-menu-text">Pricing Plans</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to={'/admin/Settings'} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-tag"></em></span><span className="nk-menu-text"> Settings</span>
                                        </Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <a href="#!" onClick={logout} className="nk-menu-link">
                                            <span className="nk-menu-icon"><em className="icon ni ni-signout"></em></span><span className="nk-menu-text">Sign Out</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="nk-sidebar-element nk-sidebar-footer">
                        <div className="nk-sidebar-footer-extended pt-3">
                            <div className="border border-light rounded-3">
                                <a className="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3" href="#!">
                                    <div className="media-group">
                                        <div className="media media-sm media-middle media-circle text-bg-primary"><img src={Helpers.serverImage(Helpers.authUser.profile_pic)} alt="" /></div>
                                        <div className="media-text">
                                            <h6 className="fs-6 mb-0 color-white">{Helpers.authUser.name}</h6>
                                        </div>
                                        <em className="icon ni ni-chevron-right ms-auto ps-1 color-white"></em>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-wrap">
                    <div className="nk-header nk-header-fixed">
                        <div className="container-fluid">
                            <div className="nk-header-wrap">
                                <div className="nk-header-logo ms-n1">
                                    <div className="nk-sidebar-toggle me-1">
                                        <button
                                            onClick={() => setShowMobileNav(true)}
                                            className="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none"
                                        >
                                            <em className="icon ni ni-menu"> </em>
                                        </button>
                                        <button
                                            onClick={() => setShowMobileNav(true)}
                                            className="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"
                                        >
                                            <em className="icon ni ni-menu"> </em>
                                        </button>
                                    </div>
                                    <a href="#!" className="logo-link">
                                        {/* <div className="logo-wrap">
                                            <img className="logo-img logo-light" src="images/logo.png" srcSet="https://copygen.themenio.com/dashboard/images/logo2x.png 2x" alt="" />
                                            <img className="logo-img logo-dark" src="images/logo-dark.png" srcSet="https://copygen.themenio.com/dashboard/images/logo-dark2x.png 2x" alt="" />
                                            <img className="logo-img logo-icon" src="images/logo-icon.png" srcSet="https://copygen.themenio.com/dashboard/images/logo-icon2x.png 2x" alt="" />
                                        </div> */}
                                        <div class="logo-wrap">
                                            <img
                                                class="logo-img logo-light"
                                                src={logo}
                                                alt=""
                                                style={{ width: '120px', height: '120px', marginTop: '-40px', marginLeft: '-10px' }}
                                            />
                                            <img
                                                class="logo-img logo-dark"
                                                src={logo}
                                                alt=""
                                                style={{ width: '120px', height: '120px', marginTop: '-40px', marginLeft: '-10px' }}
                                            />
                                            <img
                                                class="logo-img logo-icon compact-logo"
                                                src={logo}
                                                alt=""
                                                style={{ width: '120px', height: '120px', marginTop: '-40px', marginLeft: '-10px' }}
                                            />
                                        </div>
                                    </a>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav ms-2">
                                        <li className="dropdown d-inline-flex">
                                            <a data-bs-toggle="dropdown" className="d-inline-flex" href="#">
                                                <div className="media media-md media-circle media-middle text-bg-primary"><img src="images/avatar/a.png" alt="avatar" /></div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-md rounded-3">
                                                <div className="dropdown-content py-3">
                                                    <div className="border border-light rounded-3">
                                                        <div className="px-3 py-2 bg-white border-bottom border-light rounded-top-3">
                                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                                <h6 className="lead-text">Free Plan</h6>
                                                                <a className="link link-primary" href="#"><em className="ni ni-spark-fill icon text-warning"></em><span>Upgrade</span></a>
                                                            </div>
                                                            <div className="progress progress-md"><div className="progress-bar" data-progress="25%"></div></div>
                                                            <h6 className="lead-text mt-2">1,360 <span className="text-light">words left</span></h6>
                                                        </div>
                                                        <a className="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3" href="#!">
                                                            <div className="media-group">
                                                                <div className="media media-sm media-middle media-circle text-bg-primary"><img src="images/avatar/a.png" alt="avatar" /></div>
                                                                <div className="media-text">
                                                                    <h6 className="fs-6 mb-0 color-white">Shawn Mahbub</h6>
                                                                    <span className="text-light fs-7">shawn@websbd.com</span>
                                                                </div>
                                                                <em className="icon ni ni-chevron-right ms-auto ps-1"></em>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                    <div className="nk-footer">
                        <div className="container-xl">
                            <div className="d-flex align-items-center flex-wrap justify-content-between mx-n3">
                                <div className="nk-footer-links px-3">
                                    <ul className="nav nav-sm">

                                    </ul>
                                </div>
                                <div className="nk-footer-copyright fs-6 px-3">&copy; {currentYear} All Rights Reserved to <a href="#!">HumGPT</a>.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;
