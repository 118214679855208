import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import useTitle from "../../../Hooks/useTitle";
import TextInput from "../../../Components/Input";
import SearchHeader from "../../../Components/SearchHeader";
import Moment from "react-moment";
import Select from "react-select";
import { Link } from "react-router-dom";

const AdminUsers = () => {
    useTitle("Users");

    const defaultRole = {
        id: "",
        permissions: "",
    };
    const permissionOption = [
        { value: 'prompts', label: 'Prompts' },
        { value: 'proposal', label: 'Proposal' },
        { value: 'generate-reports', label: 'Generate Reports' },
        { value: 'categories', label: 'Categories' },
        { value: 'documents', label: 'Documents' },
        { value: 'buttons', label: 'Buttons' },
    ]
    const [prompts, setPrompts] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [users, setUsers] = useState([]);
    const [orgData, setOrgData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [promptOptions, setPromptOptions] = useState([]);
    const [permissionsOption, setPermissionOption] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState(permissionOption);
    const [IsDeleting, setIsDeleting] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [showUser, setShowUser] = useState(true);
    const [role, setRole] = useState(defaultRole);
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState({});


    const getUsers = () => {
        axios.get(`${Helpers.apiUrl}user/all`, Helpers.authHeaders).then(response => {
            let data = response.data;
            let options = [];
            setPrompts(data);
            for (let i = 0; i < data.length; i++) {
                let option = {
                    label: data[i].name,
                    value: data[i].id,
                    permission : data[i].permissions,
                };
                options.push(option);
            }
            setPromptOptions(options);
            setUsers(response.data);
            setOrgData(response.data);
        });
    }

    const initiateDelete = id => {
        setSelectedUser(id);
    }

    const handleDelete = (userId) => {
        setIsDeleting(true);
        axios.post(`${Helpers.apiUrl}user/Delete/${userId}`, null, Helpers.authHeaders)
            .then(response => {
                Helpers.toast("success", response.data.message);
                getUsers();
                setSelectedUser(0);
                setIsDeleting(false);
            })
            .catch(error => {
                Helpers.toast("error", error.response.data.message);
                setIsDeleting(false);
            });
    }

    const handleCancel = () => {
        setShowAddUser(false);
        setShowUser(true);
        setRole(defaultRole);
        setSelectedOption(null);
    }
    const saveRole = () => {
        setIsLoading(true);
        setErrors({});
        const payload = {
            ...role,
            permissions: role.permissions.join(',')
        };
        console.log('fex', payload);
        axios.post(`${Helpers.apiUrl}user/role`, payload, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setRole(defaultRole);
            setRoles(response.data.roles);
            setSelectedOption(null);
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        });
    }
   
    const handleUserChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log("logged",selectedOption)
        const userPermissions = selectedOption.permission.split(',');
        const updatedFilteredOptions = permissionOption.filter(option => !userPermissions.includes(option.value));
        setFilteredOptions(updatedFilteredOptions);
        setRole(prev => ({
            ...prev,
            id: selectedOption.value
        }));

    }

   
    const handlePermissionsChange = (selectedOption) => {
        setRole(prev => ({ ...prev, permissions: selectedOption ? selectedOption.map(option => option.value) : [] }));
    }
    const buttonClick = () =>{
        setShowAddUser(true);
        setShowUser(false)
    }
    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Users List</h2>
                                    <p>Registered users on HumGPT</p>
                                </div>
                                <div>
                                    {!showAddUser && <button className="btn btn-primary" onClick={buttonClick}><em class="icon ni ni-plus"></em> Add New Role</button>}

                                </div>
                            </div>
                        </div>
                        {(showUser) && <div className="nk-block">
                            <SearchHeader title={"Users List"} orgData={orgData} setData={setUsers} columns={['name']} />
                            <div className="card shadow-none">
                                <div className="card-body">
                                    <div className="row g-3 gx-gs">
                                        <div className="col-md-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. #</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Joined On</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users.length === 0 && <tr>
                                                        <td colSpan={3}>No records found...</td>
                                                    </tr>}
                                                    {users.map((user, index) => {
                                                        return (
                                                            <tr key={user.id}>
                                                                <td>{index + 1}</td>
                                                                <td>{user.name}</td>
                                                                <td>{user.email}</td>
                                                                <td><Moment date={user.created_at} format="MMM Do YYYY" /></td>
                                                                <td>
                                                                    {(selectedUser && selectedUser === user.id) ? (
                                                                        <div>
                                                                            <button className="btn btn-outline-danger btn-sm" disabled={IsDeleting} onClick={() => handleDelete(user.id)}>
                                                                                <em className="icon ni ni-check"></em><span className="ml5">{IsDeleting ? 'Deleting...' : 'Yes, Delete'}</span>
                                                                            </button>
                                                                            <button className="btn btn-outline-primary btn-sm ml10" disabled={IsDeleting} onClick={() => setSelectedUser(0)}>
                                                                                <em className="icon ni ni-cross"></em><span className="ml5">Cancel</span>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <Link to={`/admin/chats/user/${user.id}`} className="btn btn-outline-primary btn-sm ml10">
                                                                                <em className="icon ni ni-eye"></em><span className="ml5">View Chats</span>
                                                                            </Link>
                                                                             <Link to={`/admin/user/Edit-User/${user.id}`} className="btn btn-outline-success btn-sm ms-2">
                                                                                <em className="icon ni ni-edit"></em><span className="ml5">Edit</span>
                                                                            </Link>
                                                                            <button className="btn btn-outline-danger btn-sm ml10" onClick={() => initiateDelete(user.id)}>
                                                                                <em className="icon ni ni-trash"></em><span className="ml5">Delete</span>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {(showAddUser) && <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Add New Role</h3></div>
                            </div>
                            <div class="card shadown-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <div className="col-md-12 form-group">
                                            <label className="form-label">Choose User</label>
                                            <div className="form-group-wrapper">
                                                <Select options={promptOptions} placeholder="Select User" value={selectedOption} onChange={handleUserChange} />
                                                {/* <small className="text-danger">{errors.prompt ? errors.prompt[0] : ''}</small> */}
                                            </div>
                                        </div>
                                        {/* <TextInput isTextArea={true} error={errors.role} label={"Role"} cols={12} value={role.permissions} onChange={handlePermissionsChange} /> */}
                                        <div className="col-md-12 form-group">
                                            <label className="form-label">Choose Permission</label>
                                            <div className="form-group-wrapper">
                                                <Select
                                                    isMulti
                                                    name="permissions"
                                                    options={filteredOptions}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    value={filteredOptions.filter(option => role.permissions.includes(option.value))}
                                                    onChange={handlePermissionsChange}
                                                    placeholder="Select Permission" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-primary" disabled={isLoading} onClick={saveRole}>{isLoading ? 'Saving...' : 'Save Role'}</button>
                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminUsers;
